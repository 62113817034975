import React, { useEffect } from 'react';

function MetaDataBlock(props) {
  let pageData = props.page
  const metaBlock = pageData.components.find(component => component.block_type === 'MetaDataBlock');
  useEffect(() => {
    // Favicon replacement
    var favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = metaBlock.favicon;
    } else {
      var newFavicon = document.createElement('link');
      newFavicon.rel = 'icon';
      newFavicon.href = '/images/favicon_test.ico';
      document.head.appendChild(newFavicon);
    }
  }, [metaBlock.favicon]);

  function googleAnalytics() {
    var gaId = metaBlock.ga;
    var galink = document.createElement('script');
    galink.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaId;
    var galink2 = document.createElement('script');
    galink2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${gaId}', {
        'version': '${localStorage.getItem("AB_TEST_VARIABLE")}',
        'brandsite_name':'${pageData.brandsite_name}'
      });
    `;
  
    if (gaId) {
      document.head.appendChild(galink);
      document.head.appendChild(galink2);
    } else {
      console.log('gaId not found');
    }
  
    galink.addEventListener('load', function () {
      // Additional code or event listeners can be added here
    });
  }
  
  function inmarAnalytics() {
    var iaId = metaBlock.inmar_analytics;

    var ialink = document.createElement('script');
    ialink.innerHTML = 'window._oiqq = window._oiqq || [];  _oiqq.push([\'oiq_addPageLifecycle\', \'' + iaId + '\']); _oiqq.push([\'oiq_doTag\']); (function() { var oiq = document.createElement(\'script\'); oiq.type = \'text/javascript\'; oiq.async = true; oiq.src = \'https://px.owneriq.net/stas/s/br53cg.js\'; var s = document.getElementsByTagName(\'script\')[0]; s.parentNode.insertBefore(oiq, s);})();';
    
    if (iaId){
      document.head.appendChild(ialink);
    }
  }

    
    function addMetaData() {

        var keywordsMeta = document.createElement('meta');
        keywordsMeta.setAttribute('name', 'keywords');
        keywordsMeta.setAttribute('content', metaBlock.keywords);
      
        var head = document.head;
        head.insertBefore(keywordsMeta, head.firstChild);
      
        var title = document.createElement('meta');
        title.setAttribute('property', 'og:title');
        title.setAttribute('content', metaBlock.og_title);
        head.appendChild(title);
      
        var url = document.createElement('meta');
        url.setAttribute('property', 'og:URL');
        url.setAttribute('content', metaBlock.og_url);
        head.appendChild(url);
      
        var description = document.createElement('meta');
        description.setAttribute('name', 'description');
        description.setAttribute(
          'property',
          'og:description'
          );
        description.setAttribute('content', metaBlock.og_description)
        head.appendChild(description);
      
        var image = document.createElement('meta');
        image.setAttribute('property', 'og:image');
        image.setAttribute(
          'content',
          metaBlock.og_image
        );
        head.appendChild(image);
    
        var themeColor = document.createElement('meta');
        themeColor.setAttribute('name', 'theme-color');
        themeColor.setAttribute('content', metaBlock.color_theme);
        head.appendChild(themeColor);
      
        var fbDomainVerification = document.createElement('meta');
        fbDomainVerification.setAttribute(
          'name',
          'facebook-domain-verification'
        );
        fbDomainVerification.setAttribute(
          'content',
          metaBlock.fb_domain_verification
        );
        head.appendChild(fbDomainVerification);
      
        var titleTag = document.createElement('title');
        titleTag.innerHTML = metaBlock.og_title;
        head.appendChild(titleTag);
      }

      function facebookPixel() {
        var fbPixel = metaBlock.fb_pixel;
        // console.log(fbPixel);
    
        var fblink = document.createElement('script');
        fblink.innerHTML = '!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,\'script\', \'https://connect.facebook.net/en_US/fbevents.js\'); fbq(\'init\', \''+ fbPixel +'\');fbq(\'track\', \'PageView\');';
        
        var fblink2 = document.createElement('noscript');
        fblink2.innerHTML = '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id='+fbPixel+'&ev=PageView&noscript=1"/>';
    
        if (fbPixel){
          document.head.appendChild(fblink);
          document.head.appendChild(fblink2);
        }
      }
      function facebookValidation() {
    
        var fbValidation = document.createElement('meta');
        fbValidation.setAttribute('name', "facebook-domain-verification");
        fbValidation.setAttribute('content', metaBlock.fb_domain_verification)
        
        if (metaBlock.fb_domain_verification){
          document.head.appendChild(fbValidation);
        }
      }

      googleAnalytics();
      inmarAnalytics();
      addMetaData()
      facebookPixel();
      facebookValidation();
  return (<></>)
}

export default MetaDataBlock;
