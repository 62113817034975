import React, { useState } from 'react';
import './Rebates.scss';
import { Modal, Button } from 'react-bootstrap';

function FailedPopUp({ setShowFailedPopUp }) {
  return (
    <Modal show={true} onHide={() => setShowFailedPopUp(false)} backdrop="static">
  <Modal.Header style={{ backgroundColor: 'transparent', border: 'none' }}>
    <div
      type="button"
      id='popup-close-button'
      onClick={() => setShowFailedPopUp(false)}
      aria-label="Close"
      style={{ cursor: 'pointer', float: 'right', marginLeft: 'auto' }}
    >
      <span aria-hidden="true" style={{ color: "black" }}>&times;</span>
    </div>
  </Modal.Header>
  <Modal.Body style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <img src='images/exclamation-circle.png' style={{ margin: '0 auto', paddingBottom: '25px' }} alt="Exclamation Icon" />
    <div><h1 className='rebates-failed-popup-header'>Receipt submission unsuccessful</h1></div>
    {/* <div className='rebates-failed-popup-text'>For assistance, please contact our support team at (555) 555-5555 or via email at <a href="mailto:support@email.com">support@email.com</a>.</div> */}
    <div className='rebates-failed-popup-text'>Try submitting your receipt again. If you continue to have issues with your submission reach out to <a href="mailto:rebatehelp@inmar.com">rebatehelp@inmar.com</a>.</div>

  </Modal.Body>
</Modal>

  );
}

export default FailedPopUp;
