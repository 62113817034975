import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  useTheme,
  SvgIcon,
} from "@material-ui/core";
import { Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export function MobileNavigation(props) {
  const { page } = props.props;

  const location = useLocation();
  const theme = useTheme();
  const { pathname } = location;
  const currentPath  = pathname.split("/")[2];

  const [currentTab, setCurrentTab] = useState(currentPath)
  const useStyles = makeStyles((them) => ({
    appBar: {
      bottom: "0",
      top: "auto",
      background: page.color_text,

      '& .MuiTab-root': {
        "&:hover": {
          background: page.color_text,
          outline: "0",
        },
      },

      "& .MuiTabs-root .Mui-selected": {
        "&:hover": {
          background: page.color_text,
          outline: "0",
        },

        "& a:hover": {
          textDecoration: "none",
        },
      },

      "& .MuiTabs-indicator": {
        top: "0",
        background: page.color_background,
        height: "2.5px",
      },
    },
    label: {
      fontSize: "1rem",
      lineHeight: "2rem",
    },
    tabContentActive: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: page.color_background,
      outline: "0",

      "& .icon": {
        fill: page.color_background,
      },

      "& .label": {
        fill: page.color_background,
      },
    },
    tabContentInactive: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  }));
  const classes = useStyles(theme);
  const navigate = useNavigate();

  const handleChangeTab = (e, tab) => {
    setCurrentTab(tab);
    navigate(`/${page.brandsite_name}/${tab}`);
  };

  useEffect(() => {
    const cPath  =  pathname.split("/")[2];
    setCurrentTab(cPath);
  }, [pathname]);

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar variant="dense" className={classes.toolbar} disableGutters>
        <MobileStyledTabs
          value={currentTab}
          onChange={handleChangeTab}
          className={classes.tab}
        >
          {props.navigationTabs.map((tab) => (
            <MobileStyledTab
              key={tab.type}
              label={
                <Link
                  to={`/${page.brandsite_name}${tab.path}`}
                  style={{ color: "inherit" }}
                >
                  <Box
                    className={
                      currentTab === tab.slug
                        ? classes.tabContentActive
                        : classes.tabContentInactive
                    }
                  >
                    <SvgIcon
                      aria-label="Go to Wallet page"
                      component={tab.icon}
                      className="icon"
                      fontSize="large"
                    />
                    <div className={classes.label}>{tab.label}</div>
                  </Box>
                </Link>
              }
              value={tab.slug}
            />
          ))}
        </MobileStyledTabs>
      </Toolbar>
    </AppBar>
  );
}

export const MobileStyledTabs = (props) => {
  const useStyles = makeStyles(() => ({
    muiTabs: {
      "& .MuiTabs-indicator": {
        display: "flex",
        justifyContent: "center",
        background: (props) => props.textColor,
        height: "4px",
        "& > span": {
          width: "100%",
          top: "0px",
        },
      },
    },
  }));
  const classes = useStyles();

  return (
    <Tabs
      classes={classes.muiTabs}
      style={{ width: "100%" }}
      variant="fullWidth"
      {...props}
      TabIndicatorProps={{
        children: <span />,
      }}
    />
  );
};

export const MobileStyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#081632",
    fontSize: "9px",
    lineHeight: 3.5,
    fontFamily: "InterMedium",
    minHeight: theme.spacing(8),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab {...props} />);
