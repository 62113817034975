import React, { useState  } from 'react';
import './ageGate.scss';

function DateAgeGate({ page, ageGate, updateAgeGate, title, instructions, instructions2, minimum_age }) {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [isUnderAge, setIsUnderAge] = useState(false);
  const [yearError, setYearError] = useState('');
  const block = page.components.find(component => component.block_type === 'AgeGateBlock');

  const handleFormAgeSubmit = (event) => {
    event.preventDefault();
    if (isValidDate() && isValidYear()) {
      const currentDate = new Date();
      const inputDate = new Date(`${year}-${month}-${day}`);
      const ageDiff = currentDate.getFullYear() - inputDate.getFullYear();
      
      if (ageDiff < block.minimum_age || (ageDiff === block.minimum_age && currentDate < inputDate)) {
        setIsUnderAge(true);
      } else {
        updateAgeGate(false);
      }
    }
  };

  const isValidDate = () => {
    // Validate month, day, and year
    if (!month || !day || !year) {
      return false;
    }

    const numericMonth = parseInt(month, 10);
    const numericDay = parseInt(day, 10);
    const numericYear = parseInt(year, 10);

    if (isNaN(numericMonth) || isNaN(numericDay) || isNaN(numericYear)) {
      return false;
    }

    if (numericMonth < 1 || numericMonth > 12) {
      return false;
    }

    const daysInMonth = new Date(numericYear, numericMonth, 0).getDate();
    if (numericDay < 1 || numericDay > daysInMonth) {
      return false;
    }

    return true;
  };

  const isValidYear = () => {
    if (year.length !== 4) {
      setYearError('Please enter a valid year');
      return false;
    }
    setYearError('');
    return true;
  };

  return (
    (
      <div className={`age-gate-container`}>
        <div className={`age-gate`}>
          <h2>{title}</h2>
          <form onSubmit={handleFormAgeSubmit}>
            <div className="form-group">
              <label>{instructions}</label>
              <div className="date-inputs">
                <input
                  placeholder="MM"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  style={{ maxWidth: '30%' }}
                />
                <input
                  placeholder="DD"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                  style={{ maxWidth: '30%' }}
                />
                <input
                  placeholder="YYYY"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  style={{ maxWidth: '30%' }}
                />
              </div>
            </div>
            {yearError && <p className="error-message">{yearError}</p>}
            <button type="submit" style={{backgroundColor: block.button_color}}>{block.button_text}</button>
            {isUnderAge && (
              <p className="error-message">You must be {block.minimum_age} years or older to access this website.</p>
            )}
          </form>
        </div>
      </div>
    )
  );
}

export default DateAgeGate;
