import React, { useContext } from 'react';
import Navbar from 'react-bootstrap/Navbar'; // Import the Navbar component
import Nav from 'react-bootstrap/Nav'; // Import the Nav component
import { Link } from 'react-router-dom';
import { SiteConfigContext } from '../SiteConfigContext';

function Navigation(page){
    const siteConfig = useContext(SiteConfigContext);
    return (
        (siteConfig.type =='list') && (siteConfig.len > 1) && 
        <Navbar bg="light" expand="lg" className="mb-0 pb-0">
                <Navbar.Brand href={`/`}>HOME</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                {/* {siteConfig.map((page, i) => {
                    return <Nav.Link as={Link} to={page.slug}>{page.navigation_name}
                    </Nav.Link>
            })} */}
                    
                </Nav>
                </Navbar.Collapse>
            </Navbar>
    )
}

export default Navigation;