import RetailerConfig from "./retailer";
import queryString from "query-string";

const parsed = queryString.parse(window.location.search);

// Determine the base URL based on the REACT_APP_ENV variable
let baseUrl;
switch (process.env.REACT_APP_ENV) {
  case 'prod':
    baseUrl = "https://ice.dpn.inmar.com/v2/";
    break;
  case 'dev':
    baseUrl = "https://ice.test.dpn.inmar.com/v2/";
    break;
  default:
    // Fallback URL for local or undefined environments
    baseUrl = "https://ice.dpn.inmar.com/v2/";
    break;
}

const config = {
  myEnv: process.env.REACT_APP_ENV || 'local',
  retailer: RetailerConfig,
  baseUrl: parsed.testing ? "" : baseUrl,
  originUrl: window.location.origin + '/',
  urlParam: parsed
};

export default Object.freeze(config);