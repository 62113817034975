import React, { useState } from 'react';
import './Rebates.scss';
import { DropzoneArea } from 'material-ui-dropzone';

function CustomDropZone(props) {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFileUpload = (files) => {
    setUploadedFiles(files);
    props.onFileUpload(files);
  };

  return (
    <DropzoneArea
      onChange={handleFileUpload}
      maxFileSize={25 * 1024 * 1024}
      filesLimit={5}
      style={{
        color: '#0063B6',
        padding: '5px',
      }}
      acceptedFiles={['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']}
      dropzoneText={
        <>
          <img src='./images/file-arrow-up.png' style={{ margin: '5px' }} />
          <div className="custom-dropzone-text">
            <div
              style={{
                fontFamily: 'Oswald',
                fontSize: '36px',
                fontWeight: '500',
                lineHeight: '40px',
                letterSpacing: '0em',
                textAlign: 'center',
                margin: '20px',
              }}
            >
              Drag and drop receipt
            </div>

            <button
              id="drag-and-drop-button"
              type="button"
              style={{
                width: '120px',
                fontSize: '14px',
                textAlign: 'center',
                backgroundColor: '#273E90',
              }}
            >
              Select Files
            </button>
          </div>
          <img src='./images/fileRequirements.png' style={{ margin: '10px' }} />
        </>
      }
      dropzoneClass="custom-dropzone"
      showPreviewsInDropzone={false}
      showPreviews={true}
      showFileNamesInPreview={true}
      useChipsForPreview
    />
  );
}

export default CustomDropZone;
