import React from 'react';
import { Box, Container, Typography, Grid, Paper, makeStyles } from "@material-ui/core";
import '../styles/fonts.css';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  columnsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    alignItems: 'center',
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
    flex: 1,
    [theme.breakpoints.up('md')]: {
      width: 'calc(33.33% - 16px)',
    },
  },
  icon: {
    width: 80,
    height: 80,
    [theme.breakpoints.up('lg')]: {
      width: 100,
      height: 100,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
    lineHeight: 1.2,
    fontSize: '24px',
    marginBottom: theme.spacing(0),
    fontFamily: 'Switzer, sans-serif',
    [theme.breakpoints.up('lg')]: {
      fontSize: '41px',
    },
  },
  description: {
    lineHeight: 1.5,
    fontFamily: 'Switzer, sans-serif',
    fontSize: '16px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px',
    },
  },
}));

const ProgramExplanation = ({ page }) => {
  const block = page.components.find((component) => component.block_type === 'ProgramExplanationBlock');
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box className={classes.container}>
        <Box className={classes.columnsContainer}>
        <Box className={classes.column}>
          <Box>
          <img
            src="images/join.svg"
            alt="Join Icon"
            className={classes.icon}
            style={{ border: 'none' }}
          />          
          </Box>
          <Box className={classes.contentContainer}>
            <Typography variant="h5" className={classes.title}>
              {block.join_title}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {block.join_description}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.column}>
          <Box>
          <img
              src="images/scan.svg"
              alt="Scan Icon"
              className={classes.icon}
              style={{ border: 'none' }}
            /> 
          </Box>
          <Box className={classes.contentContainer}>
            <Typography variant="h5" className={classes.title}>
              {block.scan_title}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {block.scan_description}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.column}>
          <Box>
            <img
              src="images/reward.svg"
              alt="Reward Icon"
              className={classes.icon}
              style={{ border: 'none' }}
            /> 
          </Box>
          <Box className={classes.contentContainer}>
            <Typography variant="h5" className={classes.title}>
              {block.rewards_title}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {block.rewards_description}
            </Typography>
          </Box>
        </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ProgramExplanation;