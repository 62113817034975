import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { SET_OFFERS } from '../redux/reducers/offer';
import Offers from '../offers';
import OffersV2 from '../offersV2';
import RegistrationBlock from '../registration';


function OffersBlock({ updateLoadingState, page }) {
  const siteConfig = page;
  const offerBlock = siteConfig.components.find((component) => component.block_type === 'OfferBlock');
  const dispatch = useDispatch();

  const {
    config,
    config: {
      retailer: {
        dpn: { id: dpn_id },
      },
      urlParam: { ids: offerIds },
    },
  } = useSelector((state) => state);

  const mediaPId = offerBlock.mp_id;
  const hostname = window.location.hostname;
  const offers_version = offerBlock.version;
  const isLocalhost = hostname.includes('localhost');
  const devShowAllOffers = false;

  const [isLoading, setIsLoading] = useState(true);
  const storedClips = JSON.parse(localStorage.getItem('pbyt')) || { myIds: [] };

  useEffect(() => {
    fetchOffers();
  }, []); // Only fetch offers once on component mount

  function parseOffers(data) {
    const parsed = {
      clipped: [],
      available: [],
      featured: [],
    };

    data.forEach((offer) => {
      const display_start_date = new Date(offer.clipStartDate.iso);
      const display_end_date = new Date(offer.clipEndDate.iso);
      offer.display_start_date = display_start_date;
      offer.display_end_date = display_end_date;

      if (storedClips.myIds.includes(offer.mdid)) {
        offer.clipped = true;
      }
      if (Object.values(offerBlock.feature_set).find(e => e.offer_id === offer.mdid)) {
        parsed.featured.push(offer);
      } else if (config.urlParam.fid || config.urlParam.FID) {
        const fidArray = config.urlParam.fid || config.urlParam.FID || [];
        if (fidArray.includes(offer.mdid)) {
          parsed.featured.push(offer);
        } else {
          parsed.available.push(offer);
        }
      } else {
        parsed.available.push(offer);
      }

    });

    dispatch(SET_OFFERS({ collections: parsed }));
    setIsLoading(false);
  }

  function fetchOffers() {
    let url = 'offers';
    let limit = offerBlock.use_test_offers ? 30 : 99;
    if (config.urlParam.testing) {
      url = '/json/coupons.json';
    }

    let urlObject = {};
    if (offerIds && !config.urlParam.fid && !config.urlParam.FID) {
      urlObject.ids = offerIds;
    } else if (mediaPId) {
      urlObject.mediaPropertyId = mediaPId;
    }
    if (offerBlock.use_test_offers) {
      urlObject.mediaPropertyId = '';
    }
    url += '?' + queryString.stringify({ ...urlObject, limit });

    fetch(config.baseUrl + url, {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': dpn_id,
        Origin: window.location.origin,
        'If-Modified-Since': new Date(),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          parseOffers(data);
        } else {
          setIsLoading(false); // No offers found
        }
      })
      .catch((error) => {
        console.error('Error fetching offers:', error);
        setIsLoading(false); // Handle errors by setting isLoading to false
      });
  }

  const offers = {
    clipped: [],
    available: [],
    featured: [],
  };

  // Populate 'offers' object based on fetched data (this is for rendering the component)

  return (
    <div>
      <RegistrationBlock offers={offers} page={page}/>
      {offers_version === 'v2' ? (
        <OffersV2 offers={offers} page={page} /> 
      ) : (
        <Offers offers={offers} page={page} />
      )
    }
    </div>
  );
}

export default OffersBlock;
