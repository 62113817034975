import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_OFFER, CLIP_ERROR } from '../redux/reducers/offer';
import store from '../redux';

const selectOfferByMdid = (state, mdid) => {
  const offersCollections = state.offer.collections;
  const allOffers = [...offersCollections.available, ...offersCollections.featured];

  // Optionally, if you have more collections, you might need to add them to allOffers too
  // allOffers.push(...offersCollections.otherCollection);

  const offer = allOffers.find((offer) => offer.mdid === mdid);
  return offer;
};

export function OfferCardV2(props) {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.user.username);
  const stateOffer = useSelector((state) => selectOfferByMdid(state, props.mdid));
  const colors = props.colors;
  function handleClick(mdid) {
    const options = {
      endpoint: 'offers/load',
      method: 'POST',
      body: { 
        username: username, 
        id: mdid,
        // mediaPropertyId: props.mpid,
        captchaToken: '22',
        location: 'carousel'
      },
    };

    if (!username) {
      dispatch(SHOW_LOGIN({ show: true, mdid: mdid, location: 'carousel' }));
    } else {
      handleClip(options, mdid);
    }
  }
  
  function handleClip(options, mdid) {
    dispatch(CLIP_OFFER(options)).then((resp) => {
      console.log(resp);
      if (!resp.payload.success) {
        dispatch(CLIP_ERROR(resp));
      }
    });
  }

  return (
    <div style={{width: '100%', height: '100%', maxWidth: '346px',background: 'white', boxShadow: '0px 0px 0px rgba(29, 60, 109, 0.10)', borderRadius: 24, overflow: 'hidden', border: '1px #EEF0F2 solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
        <div style={{alignSelf: 'stretch', height: 326.97, background: 'white', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <img style={{width: 346, height: 326.97}} src={props.url} />
        </div>
        <div style={{alignSelf: 'stretch', height: 75, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex'}}>
            <div className='cta-container'>
            {stateOffer && stateOffer.clipped ? (
              <button className='clip-coupon-btn' style={{backgroundColor: colors.secondary_color }} onClick={() => handleClick(props.mdid)}>Clipped</button>
            ) : (
              <button className='clip-coupon-btn' style={{backgroundColor: colors.primary_color } } onClick={() => handleClick(props.mdid)}>Clip Coupon</button>
            )
            }
                
            </div>
        </div>
    </div>
  );
}
