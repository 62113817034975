import { makeStyles } from '@material-ui/core';

export const formStyles = makeStyles((theme) => ({
    muiForm: {
      '& .errorMessage': {
        fontSize: '14px',
        fontWeight: '400',
        color: '#D82B0C',
        fontStyle: 'normal',
      }
    },
    formInputControl: {
      display: 'flex !important',
      margin: '10px 0 !important',

      '& .MuiFormHelperText-root': {
        fontSize: '14px',
        color: '#212931',
        fontWeight: '700',
        marginLeft: 0,
      }
    },
    textField: {
      width: '30em',
      fontSize: '16px',
      '&>div': {
        fontSize: '16px',
        height: '48px',
        borderRadius: '10px',
      },
      '& input': {
        padding: '14.5px 14px',
      },
      '& #month':{
        padding:'0px',
        alignContent: 'center',
      },
      '& #state':{
        padding:'0px',
        alignContent: 'center',
      },
      '& #country':{
        padding:'0px',
        alignContent: 'center',
      },

      // Mobile & mini ipad < 600px
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    inputLabel: {
      fontSize: '14px',
      color: '#212931',
      fontWeight: '700',
      marginLeft: 0,
    },
    mandatoryField: {
      color: 'red',
    },
    submitBtn: {
      color: 'white',
      outline: 'none',
      padding: '1rem 1.6rem',
      fontSize: '16px',
      fontFamily: 'InterBold',
      width: '486px',
      maxWidth: '486',
      boxShadow: 'none',
      borderRadius: '20px',
      lineHeight: 1,
      backgroundColor: (props) => props.page.color_background,
      textTransform: 'capitalize',
      marginTop: '14px',

      '&:hover': {
        boxShadow: 'none',
        backgroundColor: (props) => props.page.color_background,
      },
      '&:focus': {
        boxShadow: 'none',
        backgroundColor: (props) => props.page.color_background,
        outline: 'none',
      },
      '&:focus-visible': {
        outline: 'none',
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: '1.2rem 1.6rem',
      },
    },
    errorMessage: {
      fontSize: '14px !important',
      fontWeight: '400 !important',
      color: '#D82B0C !important',
      fontStyle: 'normal !important',
    },
    errorMobile: {
      fontSize: '14px',
      fontWeight: '400',
      marginLeft: '12px',
      color: '#D82B0C',
      fontStyle: 'normal',
    },
    errortextfield: {
      width: '30em',
      borderRadius: '4px',
      background: '#FBEAE7',
      fontSize: '16px',

      '& .MuiFormHelperText-root': {
        fontSize: '14px',
        fontWeight: '400',
        color: '#D82B0C',
        fontStyle: 'normal',
      },

      '&>div': {
        fontSize: '16px',
        height: '48px',
      },
      '& input': {
        padding: '14.5px 14px',
      },
    },
    alertBoxerror: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      padding: '.5rem',
      borderRadius: '8px',
      color: ' #561104',
      background: '#FBEAE7',
      border: '1px solid #D82B0C',
    },
}));

