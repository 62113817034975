import React from 'react'
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { formStyles } from "../../../styles/cpg/form_styles";

function LP_Header({props}) {
    const navigate = useNavigate();
    const headerConfig = props.page.components.find(
    (component) => component.block_type === "LP_HeaderBlock"
  );
  const useStyles = makeStyles((theme) => ({
    container:{
        backgroundImage:`url("${headerConfig.headerBgImage}")`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height:`${headerConfig.headerBgImageHeigth}`,
    },
    loginLogo: {
      width: "240px",
      height: "auto",
      margin: '1rem 4rem',
      objectFit: "contain",
      // Mobile & mini ipad < 600px
      [theme.breakpoints.down('xs')]: {
        width: '180px',
      },
    },
    program_text: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '38.4px',
    textAlign: 'center',
    color:'#FFFFFF',
    },
    signUpBtn_text:{
        fontSize: '18px',
        fontweight: 700,
        lineheight: '28.8px',
        textAlign: 'left',
        padding: '0px 10px'

    }
  }));
  const classes = useStyles();
  const globalClasses = formStyles(props);
  return (
    <Grid item xs={12} className={classes.container} style={{textAlign:'center'}}>
        <div style={{maxWidth:'720px'}}>
            <img
                    className={classes.loginLogo}
                    alt="logo"
                    src={headerConfig.logo}
                />
                <p className={classes.program_text}>
                    {headerConfig.program_text}
                </p>
                <Button
                        onClick={() => navigate(`/${props.page.brandsite_name}/signup`)}
                        variant="contained"
                        className={globalClasses.submitBtn}
                        style={{maxWidth:'168px'}}
                    >
                        <div data-testid="signBtn" className={classes.signUpBtn_text}>
                            {headerConfig.submit_btn_text}
                        </div>
                        {/* svg icon for arrow */}
                        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.0977 11.5034L14.6602 19.9409C14.0273 20.6265 12.9199 20.6265 12.2871 19.9409C11.6016 19.3081 11.6016 18.2007 12.2871 17.5679L17.8242 11.978H1.6875C0.738281 11.978 0 11.2397 0 10.2905C0 9.39404 0.738281 8.60303 1.6875 8.60303H17.8242L12.2871 3.06592C11.6016 2.43311 11.6016 1.32568 12.2871 0.692871C12.9199 0.00732422 14.0273 0.00732422 14.6602 0.692871L23.0977 9.13037C23.7832 9.76318 23.7832 10.8706 23.0977 11.5034Z" fill="white"/>
                        </svg>
                </Button>
        </div>
    </Grid>
  )
}
export default LP_Header