import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  state: 0,
  username: undefined,
  myIds: "",
  enrollment: false,
  points: 0,
  isLogin: false,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SET_LOYALITY_POINTS: (state, action) => {
      return { ...state, points: action.payload.points };
    },
    INIT_USER: (state) => {
      return Object.assign({}, state, { state: 0 });
    },
    SET_USER: (state, action) => {
      const { username, myIds, enrollment } = action.payload;
      return Object.assign({}, state, {
        state: action.payload.state,
        username: username,
        myIds: myIds,
        enrollment: enrollment,
      });
    },
    CLEAR_USER: (state, action) => {
      return initialState;
    },
    SET_USER_LOGIN_STATUS: (state) => {
      return { ...state, isLogin: true };
    },
  },
});

export const {
  SET_USER,
  INIT_USER,
  CLEAR_USER,
  SET_USER_LOGIN_STATUS,
  SET_LOYALITY_POINTS,
} = slice.actions;

export default slice.reducer;
