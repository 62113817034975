import React from "react";
import HeaderNavigation from '../blocks/headerNavigation';

function EarnPointsPage({ page }) {
  const header = page.components.find((component) => component.block_type === 'HeaderNavigationBlock');

  return (
    <>
      {header && <HeaderNavigation page={page}/>}
      <>Earn Points Page</>
    </>
  );
}

export default EarnPointsPage;