import React, { useState } from 'react';
import './ageGate.scss';
import './ageGate.scss';

function CheckboxAgeGate({page, ageGate, updateAgeGate, title, instructions, instructions2, minimum_age }) {
  const [isChecked, setIsChecked] = useState(false);
  console.log(page)
  const block = page.components.find(component => component.block_type === 'AgeGateBlock');

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleVerifyClick = () => {
    if (isChecked) {
      updateAgeGate(false);
    }
  };

  return (
    !ageGate && (
      <div className="age-gate-container">
        <div className="age-gate">
          <h2>{title}</h2>
          <p>
            {instructions}
          </p>
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              style={{margin: '5px'}}
            />
            {instructions2}
          </label><br/>
          <button onClick={handleVerifyClick} disabled={!isChecked} style={{padding: '10px',backgroundColor: block.button_color}}>
          {block.button_text}
          </button>
        </div>
      </div>
    )
  );
}

export default CheckboxAgeGate;
