export async function requestPresignedUrls(fileNames) {
    try {
      const lambdaUrl = process.env.REACT_APP_REBATE_SUBMISSION_LAMBDA;
      const response = await fetch(lambdaUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'generate_presigned_url',
          file_names: fileNames,
          bucket_name: process.env.REACT_APP_RECEIPT_BUCKET
        })
      });
  
      if (!response.ok) {
        throw new Error(`Error from Lambda: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data.presigned_urls;
    } catch (error) {
      console.error('Error requesting presigned URLs:', error);
      throw error;
    }
  }
  
export async function uploadFilesToS3(files, presignedUrls) {
    const uploadPromises = files.map((file, index) => {
      const presignedUrl = presignedUrls[index].url;
      console.log(file.type)
      return fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      });
    });
  
    try {
      const responses = await Promise.all(uploadPromises);
      return responses.map((response, index) => {
        return {
          fileName: files[index].name,
          status: response.ok ? 'Uploaded' : 'Failed',
          key: presignedUrls[index].key
        };
      });
    } catch (error) {
      console.error('Error uploading files:', error);
      throw error;
    }
  }