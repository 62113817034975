import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import './content.scss';

function CartContentItem(props) {
  const img_path = `${props.item.product_image}`;

  function trackAddToCartEvent() {
    window.gtag('event', 'addtocartclick', {
      product: props.item.product_name,
      version: localStorage.getItem("AB_TEST_VARIABLE"),
    });
  }

  const block = props.page.components.find(component => component.block_type === 'AddToCartBlock');
  const buttonTextColor = block.a2c_button_text_color;
  const buttonBackgroundColor = block.a2c_button_background_color;

  return (
    <div className="cart-card">
      <div className='card-item'>
        <img src={img_path} className="card-img-top content-img" alt="" />
        <div className="card-title h6">{ReactHtmlParser(props.item.product_name)}</div>
        <button className="cart-btn" style={{ backgroundColor: buttonBackgroundColor}}>
          <a
            href={props.item.url}
            onClick={trackAddToCartEvent}
            target="_blank"
            rel="noopener noreferrer"
            style={{color: buttonTextColor }}
          >Add to Cart</a>
        </button>
      </div>
    </div>
  );
}

export default CartContentItem;
