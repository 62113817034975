import axios from 'axios';
import { getBaseUrl } from '../config/retailerConfig';

export const signup = 
 (
  username, 
  password,
  phone,
  first_name,
  last_name,
  month,
  year,
  address1,
  address2,
  city,
  state,
  zip,
  country,
  retailer
  ) =>
{
  return axios.post(
    `${getBaseUrl(retailer)}/user/register`,
    
      {
        "firstName": first_name,
        "lastName": last_name,
        "email": username,
        "phone":phone,
        "password": password,
        "birthMonth": month,
        "birthYear": year,
        "address1": address1,
        "address2": address2,
        "city": city,
        "state": state,
        "zip": zip,
        "country": country
      
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': retailer,
      },
    }
  );
}

export const login = (username, password, retailer) =>
  axios.post(
    `${getBaseUrl(retailer, true)}/user/login`,
    {
      username,
      password,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': retailer,
      },
    }
);

export const forgotPassword = (email, retailer) =>
  axios.post(
    `${getBaseUrl(retailer)}/user/password/forgot`,
    { email },
    {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': retailer,
      },
    }
);

export const getUserInfo = async (token, retailer) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-retailer': retailer,
    'x-auth-token': token,
  };
  try {
    const response = await axios.get(`${getBaseUrl(retailer)}/user`, { headers });
    response.data.token = token;
    return response.data;
  } catch (error) {
    return { error };
  }
};

export const getUserPoints = async (retailer) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-retailer': retailer.name,
    'x-auth-token': localStorage.getItem('token'),
  };

  try {
    const response = await axios.get(`${getBaseUrl(retailer.name)}/loyalty`, { headers });
    return response.data;
  } catch (error) {
    return { error };
  }
};
