import React from 'react';
import LazyLoad from 'react-lazy-load';
import './tiktok.scss';

function TikTokSection(page) {
    const siteConfig = page.page
    
    const tikTokBlock = siteConfig.components.find(
        (component) => component.block_type === 'TikTokBlock'
    );

    return (
        <div className="content-tiktok">
            {tikTokBlock.tiktokpost_set.map((item, i) => (
                <div key={i} className="card content-item">
                    <div className="parent">
                        <iframe src={`https://www.tiktok.com/embed/${item.tiktok_id}`} allowFullScreen scrolling="no" allow="encrypted-media;"></iframe>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TikTokSection;
