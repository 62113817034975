import React from 'react';
import store from '../redux';
import { useDispatch, useSelector } from 'react-redux';
import { CLIP_OFFER, CLIP_ERROR, SHOW_LOGIN } from '../redux/reducers/offer';

const config = store.getState().config;

function BulkOfferClip(props) {
  const dispatch = useDispatch();
  const availableOffers = useSelector((state) => state.offer.collections.available);
  const username = useSelector((state) => state.user.username);

  const siteConfig = props.siteConfig;
  const mpid = siteConfig.mpId;
  let funnel = siteConfig.funnel ? siteConfig.funnel : 'n/a';
  let channel = siteConfig.channel ? siteConfig.channel : 'n/a';

  const handleClipAllOffers = () => {
    if (!username) {
      dispatch(SHOW_LOGIN({ show: true, isBulkClip: true }));
    } else {
      availableOffers.forEach((offer) => {
        dispatch(
          CLIP_OFFER({
            endpoint: 'offers/load',
            method: 'POST',
            body: {
              id: offer.mdid,
              username: username,
              mediaPropertyId: mpid,
              channel: channel,
              funnel: funnel,
              location: 'bulk_offer_clip',
            },
          })
        );
      });
    }
  };

  return (
    <div className="flex items-center justify-center gap-6 mb-6">
      <div className="flex-grow h-[1px] bg-black"></div>
      <button
        className="px-8 py-4 bg-black rounded-[200px] border border-gray-400 flex items-center justify-center gap-2 cursor-pointer transition duration-300 hover:bg-gray-800 focus:outline-none"
        onClick={handleClipAllOffers}
      >
        <div className="text-center text-white text-lg font-bold leading-[28.80px]">
          Clip All Coupons
        </div>
      </button>
      <div className="flex-grow h-[1px] bg-black"></div>
    </div>
  );
}

export default BulkOfferClip;