import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_OFFER } from '../redux/reducers/offer';
import { SET_USER } from '../redux/reducers/user';
import { trackClick } from '../utils/gaTrackingFunctions';
import store from '../redux';


function LoginModal(props) {
  const siteConfig = props.page
  const dispatch = useDispatch();
  const {
    offer: { show_login: show, mdid, location },
    config: {
      retailer: { id: retailerId },
    },
  } = useSelector((state) => state);
  const handleClose = () => {
    setLoginError(false);
    dispatch(SHOW_LOGIN({ show: false }));
  };

  let rememberUser = useRef(false);
  let [username, setUserName] = useState();
  let [loginError, setLoginError] = useState(false);
  let [isDisabled, setEnableBtn] = useState(true);
  // let mpidbyEnv = store.getState().config.myEnv;
  // const mpid = store.getState().config.retailer.mpid[mpidbyEnv];
  const mpid = siteConfig.mpId;
  let funnel = siteConfig.funnel ? siteConfig.funnel : 'n/a';
  let channel = siteConfig.funnel ? siteConfig.funnel : 'n/a';

  function checkModalValidation(e) {
    const userInput = e.target.value;
    const regexp = /^[0-9]{10}$/;
    const splChar = /[-() ]/;
    //Checking if input field is empty
    if (userInput === '') {
      setLoginError(false);
      setUserName(userInput);
      setEnableBtn(true);
    }
    // Checking for valid numbers
    else if (regexp.test(userInput)) {
      setLoginError(false);
      setUserName(userInput);
      setEnableBtn(false);
    }
    // Checking for spaces, parenthesises, or hyphens
    else if (splChar.test(userInput)) {
      setLoginError('Must be digits');
    } else {
      setLoginError('Member Id must be 10 digit numbers');
      setEnableBtn(true);
    }
  }

  function handleValidation() {
    if (username) {
      const options = {
        endpoint: 'offers/load',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-retailer': retailerId,
          Origin: window.location.origin,
        },
        body: { 
          username: username, 
          id: mdid,
          mediaPropertyId: mpid,
          channel: channel, 
          funnel: funnel,
          location: location || 'unknown' 
          // marketingEvent: marketingEvent
        },
      };

      dispatch(CLIP_OFFER(options)).then((resp) => {
        if (resp.payload.success) {
          dispatch(SET_USER({ state: 2, username: username }));
          if (rememberUser.current.checked) {
            saveToStorage(username, JSON.stringify(resp.payload.mdid));
          }
          trackLoginEvent();
          handleClose();
        } else {
          setLoginError(resp.payload.errorMsg);
        }
      });
    } else {
      setLoginError('Member Id required');
    }
  }
  function saveToStorage(username, mdid) {
    const obj = { username: username, myIds: mdid };
    localStorage.setItem('pbyt', JSON.stringify(obj));
  }
  function RenderLoginError() {
    if (loginError) {
      return <div className="error text-danger">{loginError}</div>;
    }
    return '';
  }
  function trackLoginEvent (){
    window.gtag('event', 'account_login', { 
      retailer_id: "Publix",
      version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A',
    });
  }
  if (show) {
    const primary = siteConfig.colorPrimary;
    const secondary = siteConfig.colorSecondary;
    const links = siteConfig.colorLinks;
    return (
      <div
        className="modal fade show"
        id="loginModal"
        style={{ display: show ? 'block' : 'none' }}
        role="dialog"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: '800px' }}
        >
          <div className="modal-content text-justify">
            <div className="modal-header" style={{backgroundColor: 'gray', color: 'white'}}>
              <div className="modal-title">
                <h4>To clip digital coupons, please enter your Club Publix phone number.</h4>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number:</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      checkModalValidation(e);
                    }}
                    className="form-control"
                  />
                  <div className="form-check">
                    <input
                      type="checkbox"
                      ref={rememberUser}
                      className="form-check-input"
                      id="formLogin"
                    />
                    <label htmlFor="formLogin" className="form-check-label">
                      Remember me on this computer
                    </label>
                  </div>
                  <small className="text-muted">
                  Please enter the phone number associated with your Club Publix account. 
                  Do not use spaces, parentheses, or hyphens when entering your phone number here. 
                  If you don't have a Club Publix
                    account,{' '}
                    <a
                      style={{ color: links, fontWeight: '700', textDecoration: 'underline' }}
                      href="https://www.publix.com/myaccount/register"
                      target="_blank"
                      onClick={() => trackClick('retailer_registration', 'popup')}
                      rel="noopener noreferrer"
                    >
                      join now
                    </a>
                    .&nbsp;Terms & conditions apply.
                  </small>
                  <RenderLoginError />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                // style={{ color: primary }}
                className='btn btn-secondary'
                onClick={handleClose}
                variant="link"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
              className='btn btn-secondary'
                // disabled={isDisabled}
                // style={
                //   isDisabled
                //     ? { backgroundColor: secondary }
                //     : { backgroundColor: primary }
                // }
                onClick={handleValidation}
              >
                LOGIN
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return '';
}
export default LoginModal;
