import React from "react";
import HeaderNavigation from '../blocks/headerNavigation';

function RedeemPointsPage({ page }) {
  const header = page.components.find((component) => component.block_type === 'HeaderNavigationBlock');

  return (
    <>
      {header && <HeaderNavigation page={page}/>}
      <>Redeem Points Block</>
    </>
  );
}

export default RedeemPointsPage;