import HeaderNavigation from '../blocks/headerNavigation';
import ProgramExplanation from '../programExplanation/programExplanation';

function HomePage({ page }) {
  const header = page.components.find((component) => component.block_type === 'HeaderNavigationBlock');
  const programExpBlock = page.components.find((component) => component.block_type === 'ProgramExplanationBlock');

  return (
    <>
      {header && <HeaderNavigation page={page}/>}
      {programExpBlock && <ProgramExplanation page={page}/>}
    </>
  );
}

export default HomePage;
