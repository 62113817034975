import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderNavigation from "../blocks/headerNavigation";
import { CLEAR_USER } from '../redux/reducers/user';

function AccountPage({ page }) {
  const header = page.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSignOut = () => {
    dispatch(CLEAR_USER());
    localStorage.clear();
    navigate(`/${page.brandsite_name}`);
  };

  return (
    <>
      {header && <HeaderNavigation page={page} />}
      <>My account Page</>
      <div onClick={onSignOut}>Sign Out</div>
    </>
  );
}

export default AccountPage;
