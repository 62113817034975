import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  TextField,
  Select,
  Typography,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { formStyles } from "../styles/cpg/form_styles";
import { signup, getUserInfo } from "../service/account_service";
import {
  validatePassword,
  validateConfirmPwd,
  validateStandard,
  validateZip,
  validateEmailAddress,
} from "../shared/util/UserValidations";

function SignupWrapper(props) {
  const [values, setValues] = useState({
    userName: "",
    password: "",
    first_name: "",
    last_name: "",
    city: "",
    zip: "",
    country: "",
    state: "",
    address1: "",
    address2: "",
    confirmPassword: "",
    month: "",
    year: "",
    phone: "",
  });
  const [errors, setErrors] = useState({
    errorUsername: "",
    errorPassword: "",
    errorFirst_name: "",
    errorLast_name: "",
    errorAddress1: "",
    errorAddress2: "",
    errorCity: "",
    errorState: "",
    errorZip: "",
    errorCountry: "",
    errorBirthMonth: "",
    errorBirthYear: "",
    errorConfirmPassword: "",
    errorPhone: "",
    errorMessage: "",
  });
  const [isLoading, setLoading] = useState(false);
  const useStyles = makeStyles((theme) => ({
    paper: {
      maxWidth: 564,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(5),
      boxShadow: "none",
    },
    paperMobile: {
      maxWidth: 560,
      margin: `${theme.spacing(1)}px auto`,
      boxShadow: "none",
    },
    loginLogo: {
      width: "fit-content",
      height: "auto",
      margin: "1rem 4rem",
      objectFit: "contain",
    },
    signIntxt: {
      fontSize: "32px",
      fontFamily: "InterMedium",
      fontWeight: "900",
    },
    createAccount: {
      padding: "10px",
      fontSize: "16px",
      fontFamily: "InterMedium",
    },
    createTxt: {
      color: props.page.color_background,
      cursor: "pointer",
      fontFamily: "InterMedium",
    },
    subText: {
      padding: "10px",
      fontSize: "14px",
      fontFamily: "InterMedium",
    },
    forgotTxt: {
      fontSize: "16px",
      padding: "15px",
      fontWeight: " 700",
      color: props.page.color_background,
      cursor: "pointer",
    },
    "& #month": {
      padding: "0px!important;",
    },
  }));

  const classes = useStyles();

  const signupConfig = props.page.components.find(
    (component) => component.block_type === "SignupBlock"
  );
  const navigate = useNavigate();

  if (!signupConfig) {
    return <>Loading...</>;
  }

  const singupConfirmationText =
    signupConfig.singup_confirmation_text.replaceAll(
      "<CPG name>",
      props.page.retailer.name
    );

  const monthValues = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const usStates = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "DC", label: "District of Columbia" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
  ];
  const CountriesData = [
    { value: "US", label: "United States" },
    { value: "CA", label: "Canada" },
  ];

  const globalClasses = formStyles(props);

  // input filed values assigning
  const handleChange = (prop) => (event) => {
    const typeCheck = prop === "phone" && isNaN(event.target.value);
    const lengthCheck = prop === "phone" && 10 < event.target.value.length;
    const typeCheckZip = prop === "zip" && isNaN(event.target.value);
    const lengthCheckZip = prop === "zip" && 5 < event.target.value.length;
    if (!typeCheck && !lengthCheck && !typeCheckZip && !lengthCheckZip) {
      setValues({ ...values, [prop]: event.target.value });
    }
    setErrors({
      errorUsername: "",
      errorPassword: "",
      errorFirst_name: "",
      errorLast_name: "",
      errorPhone: "",
      errorBirthMonth: "",
      errorBirthYear: "",
      errorMessage: "",
      errorConfirmPassword: "",
      errorZip: "",
      errorAddress1: "",
      errorAddress2: "",
      errorCity: "",
      errorState: "",
      errorCountry: "",
    });
  };

  const handleFormFieldclass = (fieldValue) => {
    let txtFiledValue;
    if (errors[fieldValue]) txtFiledValue = globalClasses.textfield;
    else txtFiledValue = globalClasses.textField;
    return txtFiledValue;
  };

  const handleSignIn = async () => {
    setLoading(true);
    const userErr = validateEmailAddress(values.userName, true);
    const passwordErr = validatePassword(values.password);
    const firstNameErr =
      signupConfig.signup_first_name.visibility && 
      signupConfig.signup_first_name.mandatory && 
      validateStandard(values.first_name, "First Name");
    const lastNameErr =
      signupConfig.signup_last_name.visibility &&
      signupConfig.signup_last_name.mandatory &&
      validateStandard(values.last_name, "Last Name");
    const monthErr =
      signupConfig.signup_month.visibility &&
      signupConfig.signup_month.mandatory &&
      validateStandard(values.month, "Birth Month");
    const yearErr =
      signupConfig.signup_year.visibility &&
      signupConfig.signup_year.mandatory &&
      validateStandard(values.year, "Birth Year");
    const cityErr =
      signupConfig.signup_city.visibility &&
      signupConfig.signup_city.mandatory &&
      validateStandard(values.city, "City");
    const stateErr =
      signupConfig.signup_state.visibility &&
      signupConfig.signup_state.mandatory &&
      validateStandard(values.state, "State");
    const add1Err =
      signupConfig.signup_address1.visibility &&
      signupConfig.signup_address1.mandatory &&
      validateStandard(values.address1, "Address 1");
    const add2Err =
      signupConfig.signup_address2.visibility &&
      signupConfig.signup_address2.mandatory &&
      validateStandard(values.address2, "Address 2");
    const countryErr =
      signupConfig.signup_country.visibility &&
      signupConfig.signup_country.mandatory &&
      validateStandard(values.country, "Country");
    const zipErr =
      signupConfig.signup_zip.visibility && signupConfig.signup_zip.mandatory && validateZip(values.zip, 5);
    const confirmPwdErr = validateConfirmPwd(
      values.password,
      values.confirmPassword
    );
    const phoneErr = validateStandard(values.phone, "Phone");

    // Clear Errors
    setErrors({
      ...errors,
      errorUsername: "",
      errorPassword: "",
      errorBirthYear: "",
      errorCity: "",
      errorZip: "",
      errorBirthMonth: "",
      errorFirst_name: "",
      errorLast_name: "",
      errorState: "",
      errorCountry: "",
      errorConfirmPassword: "",
      errorPhone: "",
    });
    if (
     ( userErr == "" ||  userErr == false) &&
      passwordErr == "" &&
      cityErr == "" &&
      stateErr == "" &&
      firstNameErr == "" &&
      lastNameErr == "" &&
      monthErr == "" &&
      (yearErr == ""|| yearErr == false) &&
      zipErr == "" &&
      confirmPwdErr == "" &&
      countryErr == "" &&
      add1Err == "" &&
      add2Err == "" &&
      phoneErr == ""
    ) {
      const loginRes = signup(
        values.userName,
        values.password,
        values.phone,
        values.first_name,
        values.last_name,
        values.month,
        values.year,
        values.address1,
        values.address2,
        values.city,
        values.state,
        values.zip,
        values.country,
        props.page.retailer.name
      );

      loginRes
        .then((res) => {
          setLoading(false);
          const { token } = res.data;
          if (token) {
            navigate(`/${props.page.brandsite_name}/home`);
            localStorage.setItem("token", token);
            getUserInfo(token, props.page.retailer.name).then(
              (user) => {
                localStorage.setItem("userData", JSON.stringify(user));
              }
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data.status == 409) {
            err.response.data.fields[0] === "email"
              ? // setting error for email
                setErrors({
                  ...errors,
                  errorMessage: `"Error: There is already an account with this email address. Please log in.`,
                  errorUsername: `"Error: There is already an account with this email address. Please log in.`,
                })
              : // setting error for phone number
                setErrors({
                  ...errors,
                  errorMessage: `"Error: There is already an account with this phone number. Please log in.`,
                  errorPhone: `"Error: There is already an account with this phone number. Please log in.`,
                });
          } else {
            setErrors({
              ...errors,
              errorMessage:
                "Some Error has occurred. Please try after some time.",
            });
          }
        });
    } else {
      setErrors({
        ...errors,
        errorUsername: userErr,
        errorPassword: passwordErr,
        errorBirthYear: yearErr,
        errorBirthMonth: monthErr,
        errorFirst_name: firstNameErr,
        errorLast_name: lastNameErr,
        errorZip: zipErr,
        errorConfirmPassword: confirmPwdErr,
        errorCity: cityErr,
        errorCountry: countryErr,
        errorState: stateErr,
        errorAddress1: add1Err,
        errorAddress2: add2Err,
        errorPhone: phoneErr,
      });
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <Grid item xs={12} zeroMinWidth>
        <a onClick={() => navigate(`/${props.page.brandsite_name}`)}>
          <img
            tabIndex={0}
            className={classes.loginLogo}
            alt="logo"
            src={signupConfig.logo}
          />
        </a>
      </Grid>

      <Paper className={classes.paper}>
        <Grid container wrap="nowrap" spacing={1}>
          <Grid item xs={12} zeroMinWidth style={{ textAlign: "center" }}>
            <Typography
              tabIndex={0}
              className={classes.signIntxt}
              noWrap
              data-testid="signIn"
            >
              {signupConfig.page_title}
            </Typography>

            <Typography
              tabIndex={0}
              className={classes.createAccount}
              data-testid="donthaveAccount"
            >
              {signupConfig.signup_sub_titleText}
            </Typography>

            {errors.errorMessage !== "" &&
              errors.errorMessage !== undefined && (
                <div className={globalClasses.alertBoxerror}>
                  {errors.errorMessage}
                </div>
              )}

            <Grid container xs={12} spacing={1}>
              {/* first name */}
              {signupConfig.signup_first_name.visibility && (
                <Grid item xs={6}>
                  <FormControl
                    className={globalClasses.formInputControl}
                    variant="outlined"
                  >
                    <label htmlFor="first_name">
                      <FormHelperText
                        className={globalClasses.inputLabel}
                        id="outlined-weight-helper-text"
                      >
                        First Name{" "}
                        {signupConfig.signup_first_name.mandatory && (
                          <span className={globalClasses.mandatoryField}>
                            *
                          </span>
                        )}
                      </FormHelperText>
                    </label>

                    <TextField
                     style={{width:'auto'}}
                      id="first_name"
                      variant="outlined"
                      error={signupConfig.signup_first_name.mandatory && errors.errorFirst_name !== ""}
                      value={values.first_name}
                      onChange={handleChange("first_name")}
                      className={handleFormFieldclass("errorFirst_Name")}
                    />
                  </FormControl>
                  {signupConfig.signup_first_name.mandatory &&
                    errors.errorFirst_name !== "" && (
                      <FormHelperText
                        className={globalClasses.errorMessage}
                        id="outlined-weight-helper-text"
                      >
                        {errors.errorFirst_name}
                      </FormHelperText>
                    )}
                </Grid>
              )}
              {/* last name */}
              {signupConfig.signup_last_name.visibility && (
                <Grid item xs={6}>
                  <FormControl
                    className={globalClasses.formInputControl}
                    variant="outlined"
                  >
                    <label htmlFor="last_name">
                      <FormHelperText
                        className={globalClasses.inputLabel}
                        id="outlined-weight-helper-text"
                      >
                        Last Name{" "}
                        {signupConfig.signup_last_name.mandatory && (
                          <span className={globalClasses.mandatoryField}>
                            *
                          </span>
                        )}
                      </FormHelperText>
                    </label>

                    <TextField
                     style={{width:'auto'}}
                      id="last_name"
                      variant="outlined"
                      error={ signupConfig.signup_last_name.mandatory && errors.errorLast_name !== ""}
                      value={values.last_name}
                      onChange={handleChange("last_name")}
                      className={handleFormFieldclass("errorLast_name")}
                    />
                  </FormControl>
                  {signupConfig.signup_last_name.mandatory &&
                    errors.errorLast_name !== "" && (
                      <FormHelperText
                        className={globalClasses.errorMessage}
                        id="outlined-weight-helper-text"
                      >
                        {errors.errorLast_name}
                      </FormHelperText>
                    )}
                </Grid>
              )}

              {/* email */}
              <Grid item xs={12}>
                <FormControl
                  className={globalClasses.formInputControl}
                  variant="outlined"
                >
                  <label htmlFor="Email">
                    <FormHelperText
                      className={globalClasses.inputLabel}
                      id="outlined-weight-helper-text"
                    >
                      Email{" "}
                      <span className={globalClasses.mandatoryField}>*</span>
                    </FormHelperText>
                  </label>

                  <TextField
                    id="Email"
                    variant="outlined"
                    error={errors.errorUsername !== ""}
                    value={values.Email}
                    onChange={handleChange("userName")}
                    className={handleFormFieldclass("errorUserName")}
                    style={{width:'auto'}}
                  />
                </FormControl>
                {errors.errorUsername !== "" && (
                  <FormHelperText
                    className={globalClasses.errorMessage}
                    id="outlined-weight-helper-text"
                  >
                    {errors.errorUsername}
                  </FormHelperText>
                )}
              </Grid>

              {/* phone */}
              <Grid item xs={12}>
                <FormControl
                  className={globalClasses.formInputControl}
                  variant="outlined"
                >
                  <label htmlFor="phone">
                    <FormHelperText
                      className={globalClasses.inputLabel}
                      id="outlined-weight-helper-text"
                    >
                      Phone{" "}
                      <span className={globalClasses.mandatoryField}>*</span>
                    </FormHelperText>
                  </label>

                  <TextField
                    style={{width:'auto'}}
                    id="phone"
                    type="tel"
                    name="phoneNumber"
                    maxlength="10"
                    variant="outlined"
                    error={errors.errorPhone !== ""}
                    value={values.phone}
                    onChange={handleChange("phone")}
                    className={handleFormFieldclass("errorPhone")}
                  />
                </FormControl>
                {errors.errorPhone !== "" && (
                  <FormHelperText
                    className={globalClasses.errorMessage}
                    id="outlined-weight-helper-text"
                  >
                    {errors.errorPhone}
                  </FormHelperText>
                )}
              </Grid>

              {/* birth month */}
              {signupConfig.signup_month.visibility && (
                <Grid item xs={6}>
                  <FormControl
                    className={globalClasses.formInputControl}
                    variant="outlined"
                  >
                    <label htmlFor="month">
                      <FormHelperText
                        className={globalClasses.inputLabel}
                        id="outlined-weight-helper-text"
                      >
                        Birth Month{" "}
                        {signupConfig.signup_month.mandatory && (
                          <span className={globalClasses.mandatoryField}>
                            *
                          </span>
                        )}
                      </FormHelperText>
                    </label>

                    <Select
                      id="month"
                      variant="outlined"
                      error={signupConfig.signup_month.mandatory && errors.errorBirthMonth !== ""}
                      value={values.month}
                      onChange={handleChange("month")}
                      className={handleFormFieldclass("errorBirthMonth")}
                      style={{ borderRadius: "10px", width:'auto' }}
                    >
                      {monthValues.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {signupConfig.signup_month.mandatory &&
                    errors.errorBirthMonth !== "" && (
                      <FormHelperText
                        className={globalClasses.errorMessage}
                        id="outlined-weight-helper-text"
                      >
                        {errors.errorBirthMonth}
                      </FormHelperText>
                    )}
                </Grid>
              )}

              {/* birth year */}
              {signupConfig.signup_year.visibility && (
                <Grid item xs={6}>
                  <FormControl
                    className={globalClasses.formInputControl}
                    variant="outlined"
                  >
                    <label htmlFor="Birth Year">
                      <FormHelperText
                        className={globalClasses.inputLabel}
                        id="outlined-weight-helper-text"
                      >
                        Birth Year{" "}
                        {signupConfig.signup_year.mandatory && (
                          <span className={globalClasses.mandatoryField}>
                            *
                          </span>
                        )}
                      </FormHelperText>
                    </label>

                    <TextField
                      style={{width:'auto'}}
                      id="year"
                      variant="outlined"
                      error={signupConfig.signup_year.mandatory && errors.errorBirthYear !== ""}
                      value={values.year}
                      onChange={handleChange("year")}
                      className={handleFormFieldclass("errorBirthYear")}
                    />
                  </FormControl>
                  {signupConfig.signup_year.mandatory &&
                    errors.errorBirthYear !== "" && (
                      <FormHelperText
                        className={globalClasses.errorMessage}
                        id="outlined-weight-helper-text"
                      >
                        {errors.errorBirthYear}
                      </FormHelperText>
                    )}
                </Grid>
              )}

              {/* address 1 */}
              {signupConfig.signup_address1.visibility && (
                <Grid item xs={12}>
                  <FormControl
                    className={globalClasses.formInputControl}
                    variant="outlined"
                  >
                    <label htmlFor="address1">
                      <FormHelperText
                        className={globalClasses.inputLabel}
                        id="outlined-weight-helper-text"
                      >
                        Address 1{" "}
                        {signupConfig.signup_address1.mandatory && (
                          <span className={globalClasses.mandatoryField}>
                            *
                          </span>
                        )}
                      </FormHelperText>
                    </label>

                    <TextField
                      style={{width:'auto'}}
                      id="address1"
                      variant="outlined"
                      error={signupConfig.signup_address1.mandatory && errors.errorAddress1 !== ""}
                      value={values.address1}
                      onChange={handleChange("address1")}
                      className={handleFormFieldclass("errorAdd1")}
                    />
                  </FormControl>
                  {signupConfig.signup_address1.mandatory &&
                    errors.errorAddress1 !== "" && (
                      <FormHelperText
                        className={globalClasses.errorMessage}
                        id="outlined-weight-helper-text"
                      >
                        {errors.errorAddress1}
                      </FormHelperText>
                    )}
                </Grid>
              )}

              {/* address 2 */}
              {signupConfig.signup_address2.visibility && (
                <Grid item xs={12}>
                  <FormControl
                    className={globalClasses.formInputControl}
                    variant="outlined"
                  >
                    <label htmlFor="address2">
                      <FormHelperText
                        className={globalClasses.inputLabel}
                        id="outlined-weight-helper-text"
                      >
                        Address 2{" "}
                        {signupConfig.signup_address2.mandatory && (
                          <span className={globalClasses.mandatoryField}>
                            *
                          </span>
                        )}
                      </FormHelperText>
                    </label>

                    <TextField
                      style={{width:'auto'}}
                      id="address2"
                      variant="outlined"
                      error={signupConfig.signup_address2.mandatory && errors.errorAddress2 !== ""}
                      value={values.address2}
                      onChange={handleChange("address2")}
                      className={handleFormFieldclass("errorAdd2")}
                    />
                  </FormControl>
                  {signupConfig.signup_address1.mandatory &&
                    errors.errorAddress2 !== "" && (
                      <FormHelperText
                        className={globalClasses.errorMessage}
                        id="outlined-weight-helper-text"
                      >
                        {errors.errorAddress2}
                      </FormHelperText>
                    )}
                </Grid>
              )}

              {/* city */}
              {signupConfig.signup_city.visibility && (
                <Grid item xs={6}>
                  <FormControl
                    className={globalClasses.formInputControl}
                    variant="outlined"
                  >
                    <label htmlFor="city">
                      <FormHelperText
                        className={globalClasses.inputLabel}
                        id="outlined-weight-helper-text"
                      >
                        City{" "}
                        {signupConfig.signup_city.mandatory && (
                          <span className={globalClasses.mandatoryField}>
                            *
                          </span>
                        )}
                      </FormHelperText>
                    </label>

                    <TextField
                      style={{width:'auto'}}
                      id="city"
                      variant="outlined"
                      error={signupConfig.signup_city.mandatory && errors.errorCity !== ""}
                      value={values.city}
                      onChange={handleChange("city")}
                      className={handleFormFieldclass("errorCity")}
                    />
                  </FormControl>
                  {signupConfig.signup_city.mandatory &&
                    errors.errorCity !== "" && (
                      <FormHelperText
                        className={globalClasses.errorMessage}
                        id="outlined-weight-helper-text"
                      >
                        {errors.errorCity}
                      </FormHelperText>
                    )}
                </Grid>
              )}
              {/* state */}
              {signupConfig.signup_state.visibility && (
                <Grid item xs={6}>
                  <FormControl
                    className={globalClasses.formInputControl}
                    variant="outlined"
                  >
                    <label htmlFor="state">
                      <FormHelperText
                        className={globalClasses.inputLabel}
                        id="outlined-weight-helper-text"
                      >
                        State{" "}
                        {signupConfig.signup_state.mandatory && (
                          <span className={globalClasses.mandatoryField}>
                            *
                          </span>
                        )}
                      </FormHelperText>
                    </label>
                    <Select
                      id="state"
                      variant="outlined"
                      error={signupConfig.signup_state.mandatory && errors.errorState != ""}
                      value={values.state}
                      onChange={handleChange("state")}
                      className={handleFormFieldclass("errorState")}
                      style={{ borderRadius: "10px", width:'auto' }}
                    >
                      {usStates.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {signupConfig.signup_state.mandatory &&
                    errors.errorState !== "" && (
                      <FormHelperText
                        className={globalClasses.errorMessage}
                        id="outlined-weight-helper-text"
                      >
                        {errors.errorState}
                      </FormHelperText>
                    )}
                </Grid>
              )}

              {/* zip */}
              {signupConfig.signup_zip.visibility && (
                <Grid item xs={6}>
                  <FormControl
                    className={globalClasses.formInputControl}
                    variant="outlined"
                  >
                    <label htmlFor="zip">
                      <FormHelperText
                        className={globalClasses.inputLabel}
                        id="outlined-weight-helper-text"
                      >
                        Zip{" "}
                        {signupConfig.signup_zip.mandatory && (
                          <span className={globalClasses.mandatoryField}>
                            *
                          </span>
                        )}
                      </FormHelperText>
                    </label>

                    <TextField
                      style={{width:'auto'}}
                      id="zip"
                      variant="outlined"
                      error={signupConfig.signup_zip.mandatory && errors.errorZip !== ""}
                      value={values.zip}
                      onChange={handleChange("zip")}
                      className={handleFormFieldclass("errorZip")}
                    />
                  </FormControl>
                  {signupConfig.signup_zip.mandatory &&
                    errors.errorZip !== "" && (
                      <FormHelperText
                        className={globalClasses.errorMessage}
                        id="outlined-weight-helper-text"
                      >
                        {errors.errorZip}
                      </FormHelperText>
                    )}
                </Grid>
              )}
              {/* country */}
              {signupConfig.signup_country.visibility && (
                <Grid item xs={6}>
                  <FormControl
                    className={globalClasses.formInputControl}
                    variant="outlined"
                  >
                    <label htmlFor="country">
                      <FormHelperText
                        className={globalClasses.inputLabel}
                        id="outlined-weight-helper-text"
                      >
                        Country{" "}
                        {signupConfig.signup_country.mandatory && (
                          <span className={globalClasses.mandatoryField}>
                            *
                          </span>
                        )}
                      </FormHelperText>
                    </label>
                    <Select
                      id="country"
                      variant="outlined"
                      error={signupConfig.signup_country.mandatory && errors.errorCountry != ""}
                      value={values.country}
                      onChange={handleChange("country")}
                      className={handleFormFieldclass("errorCountry")}
                      style={{ borderRadius: "10px", width:'auto' }}
                    >
                      {CountriesData.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {signupConfig.signup_country.mandatory &&
                    errors.errorCountry !== "" && (
                      <FormHelperText
                        className={globalClasses.errorMessage}
                        id="outlined-weight-helper-text"
                      >
                        {errors.errorCountry}
                      </FormHelperText>
                    )}
                </Grid>
              )}

              {/* password */}
              <Grid item xs={12}>
                <FormControl
                  className={globalClasses.formInputControl}
                  variant="outlined"
                >
                  <label htmlFor="outlined-basic-password">
                    <FormHelperText
                      className={globalClasses.inputLabel}
                      id="outlined-weight-helper-text"
                    >
                      Password{" "}
                      <span className={globalClasses.mandatoryField}>*</span>
                    </FormHelperText>
                  </label>
                  <TextField
                    style={{width:'auto'}}
                    id="outlined-basic-password"
                    variant="outlined"
                    error={errors.errorPassword !== ""}
                    value={values.password}
                    type="password"
                    onChange={handleChange("password")}
                    className={handleFormFieldclass("errorPassword")}
                  />
                </FormControl>
                {errors.errorPassword !== "" && (
                  <FormHelperText
                    className={globalClasses.errorMessage}
                    id="outlined-weight-helper-text"
                  >
                    {errors.errorPassword}
                  </FormHelperText>
                )}
              </Grid>

              {/* confirm password */}
              <Grid item xs={12}>
                <FormControl
                  className={globalClasses.formInputControl}

                  variant="outlined"
                >
                  <label htmlFor="outlined-basic-confirmpassword">
                    <FormHelperText
                      className={globalClasses.inputLabel}
                      id="outlined-weight-helper-text"
                    >
                      Confirm Password{" "}
                      <span className={globalClasses.mandatoryField}>*</span>
                    </FormHelperText>
                  </label>
                  <TextField
                    style={{width:'auto'}}
                    id="confirmPassword"
                    variant="outlined"
                    error={errors.errorConfirmPassword !== ""}
                    value={values.confirmPassword}
                    type="password"
                    onChange={handleChange("confirmPassword")}
                    className={handleFormFieldclass("errorConfirmPassword")}
                  />
                </FormControl>
                {errors.errorConfirmPassword !== "" && (
                  <FormHelperText
                    className={globalClasses.errorMessage}
                    id="outlined-weight-helper-text"
                  >
                    Password does not match.
                  </FormHelperText>
                )}
              </Grid>

              {/* signup Confirmation text */}
              <Grid item xs={12}>
                  <Typography
                    tabIndex={0}
                    style={{ whiteSpace: "unset", textAlign: "left" }}
                    className={classes.createAccount}
                    noWrap
                    data-testid="signUp"
                  >
                    {singupConfirmationText}
                  </Typography>
              </Grid>

              {/* singup button */}
              <Grid item xs={12}>
                <FormControl className={globalClasses.formInputControl}>
                <Button
                  variant="contained"
                  onClick={handleSignIn}
                  disabled={isLoading}
                  className={globalClasses.submitBtn}
                >
                  {!isLoading ? (
                    <div data-testid="signBtn" className="signBtn">
                      {signupConfig.submit_btn_text}
                    </div>
                  ) : (
                    <div className="loader" />
                  )}
                </Button>
                </FormControl>
              </Grid>
            </Grid>

            <Typography
              tabIndex={0}
              onClick={() => navigate(`/${props.page.brandsite_name}/login`)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigate(`/${props.page.brandsite_name}/login`);
                }
              }}
              className={classes.createAccount}
              noWrap
              data-testid="donthaveAccount"
            >
              Already have an account?
              <span className={classes.createTxt} data-testid="createAccount">
                {" "}
                Log In
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default SignupWrapper;
