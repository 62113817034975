import React, { useState, useEffect } from 'react';

import { Modal } from 'react-bootstrap';
import './qualifyingProducts.scss';

function QualifyingProductsBlock({ show, handleClose, page }) {
  const siteConfig = page
  const block = siteConfig.components.find(component => component.block_type === 'QualifyingProductsBlock');
  const [brandsAndProducts, setBrandsAndProducts] = useState([]);
  const [heading, setHeading] = useState("");
  
  useEffect(() => {
    fetchBrandsAndProducts();
  }, []);
  
  function fetchBrandsAndProducts() {
    let productInfo = '/qualifyingProducts.json';
  
    fetch(productInfo, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setBrandsAndProducts(data.content);
        setHeading(data.heading);
      })
      .catch(error => {
        console.error('Error fetching brands and products:', error);
      });
  }


  // const heading = block.qualifying_products_popup_heading

  return (
    <>
      <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet" />
      <Modal show={show} onHide={handleClose} scrollable className="qualifying-modal">
        <Modal.Header>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
      {brandsAndProducts.map((brandObj, index) => (
        <div key={index} className="brand-list">
          <h2>{brandObj.header}</h2>
          <div className="product-list">
            {brandObj.items.map((product, productIndex) => (
              <div>
                <div key={productIndex}>{product.name}</div>
                <div className="upc">{`UPC: ${product.upc}`}</div>
              </div>
            ))}
          </div>
          {/* {index !== brandsAndProducts.length - 1 && <div className="divider"></div>} */}
        </div>
      ))}
          {/* {brandsAndProducts.map((brandObj, index) => (
            <div key={index} className="brand-list">
              <h2>{brandObj.header}</h2>
              <div className="product-list"> */}
                {/* {brandObj.items.map((product, productIndex) => (
                  <div>
                    <div key={productIndex}>{product.name}</div>
                    <div className="upc">{`UPC: ${product.upc}`}</div>
                  </div>
                // ))} */}
                {/* <div dangerouslySetInnerHTML={{ __html: block.qualifying_products_popup_list }}/> */}
                
                {/* {index !== brandsAndProducts.length - 1 && <div className="divider"></div>} */}
                {/* </div> */}
              {/* </div> */}
              {/* </div> */}
          {/* ) */}
          {/* ) */}
          {/* } */}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="close-button" onClick={handleClose}>
            CLOSE
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )};

export default QualifyingProductsBlock;


// import React, { useState, useEffect } from 'react';
// import { Modal } from 'react-bootstrap';
// import './qualifyingProducts.scss';

// const QualifyingList = ({ show, handleClose }) => {

//   return (
//     <>
//       <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet" />
//       <Modal show={show} onHide={handleClose} scrollable className="qualifying-modal">
//         <Modal.Header closeButton>
//           <Modal.Title>{heading}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="modal-body">
//         </Modal.Body>
//         <Modal.Footer>
//           <button type="button" className="close-button" onClick={handleClose}>
//             CLOSE
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default QualifyingList;
