import React, { useState } from 'react';
import './faq.scss';

function FAQBlock(props) {
  const block = props.page.components.find(
    (component) => component.block_type === 'FAQBlock'
  );

  const [faqData, setFAQData] = useState(
    block.faq_set.map((faq) => ({ ...faq, open: false }))
  );

  const trackFAQEvent = (faq_question) => {
    // Track the event using Google Analytics or other tracking tools
    console.log('FAQ event tracked');
    console.log(faq_question);
    if (window.gtag) {
      window.gtag('event', 'Expand_FAQ', {
        FAQ_question: faq_question,
        version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A',
      });
    }
  };

  const handleToggle = (index) => {
    setFAQData((prevFAQData) =>
      prevFAQData.map((faq, i) =>
        i === index ? { ...faq, open: !faq.open } : { ...faq, open: false }
      )
    );
    trackFAQEvent(faqData[index].question); // Track the event when FAQ is expanded
  };

  return (
    <div className="faq-container" style={{backgroundColor: block.background_color}}>
      <div className="header" style={{color: block.color_header}}>{block.faq_title}</div>
      <div className="faq-content">
        {faqData.map((faqItem, index) => (
          <div key={faqItem.id} className={`card ${faqItem.open ? 'collapse' : ''}`}>
            <div
              className="card-header"
              style={{ color: block.color_question_text, backgroundColor: block.color_question_background}}
              onClick={() => handleToggle(index)} // Add onClick handler to toggle accordion
            >
              <div className="question-container">
                <div className="question-text">{faqItem.question}</div>
                <div
                  className="chevron-icon"
                  style={{ backgroundColor: 'transparent' }}
                >
                  {!faqItem.open ? <svg width="27" height="15" viewBox="0 0 27 15" fill={block.color_chevron} xmlns="http://www.w3.org/2000/svg">
<path d="M1.25391 11.5039L12.4453 0.78125C12.7969 0.488281 13.1484 0.3125 13.5 0.3125C13.8516 0.3125 14.1445 0.429688 14.4375 0.664062L25.6289 11.3867C26.2148 11.9727 26.2148 12.8516 25.6875 13.3789C25.1602 13.9648 24.2812 13.9648 23.6953 13.4375L13.5 3.71094L3.1875 13.5547C2.66016 14.082 1.72266 14.082 1.19531 13.4961C0.667969 12.9102 0.667969 12.0312 1.25391 11.5039Z" fill={block.color_chevron}/>
</svg> : <svg width="27" height="15" viewBox="0 0 27 15" fill={block.color_chevron} xmlns="http://www.w3.org/2000/svg">
<path d="M25.6875 3.05469L14.4961 13.7773C14.1445 14.0703 13.793 14.1875 13.5 14.1875C13.1484 14.1875 12.7969 14.0703 12.5039 13.8359L1.25391 3.05469C0.667969 2.52734 0.667969 1.58984 1.19531 1.0625C1.72266 0.476562 2.66016 0.476562 3.1875 1.00391L13.5 10.8477L23.7539 1.00391C24.2812 0.476562 25.2188 0.476562 25.7461 1.0625C26.2734 1.58984 26.2734 2.52734 25.6875 3.05469Z" fill={block.color_chevron}/>
</svg>}
                </div>
              </div>
            </div>
            {faqItem.open && (
              <div className="card-body" style={{ color: block.color_answer_text, backgroundColor: block.color_answer_background}} dangerouslySetInnerHTML={{ __html: faqItem.answer }} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQBlock;
