import React from 'react';
import './FreeText.scss';

function About({ freeTextBlock }) {
  const { title, column_1, column_2, column_3 } = freeTextBlock;

  const renderColumns = () => {
    const columns = [];
    if (column_1) {
      columns.push(
        <div
          className="free-text-column"
          dangerouslySetInnerHTML={{ __html: column_1 }}
        />
      );
    }
    if (column_2) {
      columns.push(
        <div
          className="free-text-column"
          dangerouslySetInnerHTML={{ __html: column_2 }}
        />
      );
    }
    if (column_3) {
      columns.push(
        <div
          className="free-text-column"
          dangerouslySetInnerHTML={{ __html: column_3 }}
        />
      );
    }
    return columns.map((column, index) => (
      <div key={index} className={`column column-${index + 1}`}>
        {column}
      </div>
    ));
  };

  return (
    <div
      className="free-text-block"
      style={{
        margin: `${freeTextBlock.margin}px`,
        padding: `${freeTextBlock.padding}px`,
        width: `${freeTextBlock.width}%`,
      }}
    >
      {title && <h2 className="free-text-title">{title}</h2>}
      <div className="columns-container">{renderColumns()}</div>
    </div>
  );
}

export default About;