import React, { useState } from 'react';
import './Rebates.scss';
import FailedPopUp from './FailedPopUp';
import SuccessPopUp from './SuccessPopUp';
import { requestPresignedUrls, uploadFilesToS3 } from '../utils/s3Utils';

function PreviewSubmission({page, formData, edit, uploadedFiles, paymentMethod, closePreview, handleSuccess, rebatesData, clearForm, setFormSubmitted }) {
  
  const block = page.components.find(component => component.block_type === 'Rebates');
  setFormSubmitted(false);
  let paymentTypeId;
  if (paymentMethod === 'PayPal') {
    paymentTypeId = 1;
  } else if (paymentMethod === 'Venmo') {
    console.log('selected venmo')
    paymentTypeId = 2;
  } else {
    paymentTypeId = 0;
  }

  const formattedDate = new Date().toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  console.log(uploadedFiles);

  const [showFailedPopUp, setShowFailedPopUp] = useState(false)
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false)
  const [submissionNumber, setSubmissionNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fileNames = uploadedFiles.map(file => file.name);

  const handleCancelClick = (e) => {
    clearForm();
    closePreview();
  }

  const handleSubmitClick = (e) => {
    setIsLoading(true);

    requestPresignedUrls(fileNames)
      .then(presignedUrls => {
        return uploadFilesToS3(uploadedFiles, presignedUrls).then(uploadResults => {
          return uploadResults.map((result, index) => {
            return {
              ...result,
              presignedUrl: presignedUrls[index].url,
              originalFileName: fileNames[index]
            };
          });
        });
      })
      .then(uploadResults => {
        console.log("Upload Results:", uploadResults);
        const cleanVenmoNumber = formData.venmoNumber.replace(/\D/g, '');
        const request_data = {
          // env: process.env.REACT_APP_ENV || 'test',
          env: 'prod',
          fileInfos: uploadResults,
          email: formData.paypalemail || formData.email, // Discuss this with team, there is no 'payment email' in rebates API
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: cleanVenmoNumber || '',
          offerCode: block.offer_code,
          action: 'submit_receipt',
          paymentTypeId: paymentTypeId
        };
        console.log(request_data);
  
        const requestData = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(request_data),
        };
        return fetch(process.env.REACT_APP_REBATE_SUBMISSION_LAMBDA, requestData)
          .then(response => response.json())
          .then(example_response => {
            const submissionNumber = example_response.response.submissionNumber;
            console.log("Submission Number:", submissionNumber);
            setSubmissionNumber(submissionNumber);
            setIsLoading(false);
            setShowSuccessPopUp(true);
          });
      })
      .catch(error => {
        console.error("Error:", error);
        setIsLoading(false);
        setShowFailedPopUp(true);
      });
  }

  const renderFilePreviews = () => {
    return (
      
      <div className='file-previews'>
        {uploadedFiles.map((file, index) => (
          <div className='receipt-upload-container'>
        <div className='image-column'>
          <div key={index} className='file-preview'>
            <img src={URL.createObjectURL(file)} alt={`Uploaded Image ${index + 1}`} />
          </div>
        </div>
      <div className='info-column'>
        <div className='receipt-file-name'>{file.name}</div>
        <div className='receipt-upload-date'>Uploaded on {formattedDate}</div>
      </div>
          </div>
        ))}
      </div>
    );
  }


  return (
    <div>
      {showFailedPopUp && <FailedPopUp setShowFailedPopUp={setShowFailedPopUp}/>}
      {showSuccessPopUp && (
        <SuccessPopUp
          setShowSuccessPopUp={setShowSuccessPopUp}
          firstName={formData.firstName}
          lastName={formData.lastName}
          email={formData.email}
          paymentMethod={paymentMethod}
          paymentEmail={paymentMethod === 'PayPal' ? formData.paypalemail : ''}
          submissionNumber={submissionNumber}
          venmoNumber={paymentMethod === 'Venmo' ? formData.venmoNumber : ''}
          handleSuccess={handleSuccess}
          clearForm={clearForm}
        />
      )}
      <div className='submission-form-container' style={{backgroundColor: rebatesData.color_background_2}}>
      
        <div className='submission-form-column-right'>
          <div className='submission-form-header'>
            <h2>REVIEW SUBMISSION</h2>
          </div>
          <div className="container mt-1">
            <form>
              <div className="mb-3 label-left">
                <label htmlFor="firstName" className="form-label rebates-form-label">First Name <span className='red'>*</span></label>
                <div>
                    {formData.firstName}
                    </div>
              </div>
              <div className="mb-3 label-left">
                <label htmlFor="lastName" className="form-label rebates-form-label">Last Name <span className='red'>*</span></label>
                <div>
                    {formData.lastName}
                    </div>
              </div>
              <div className="mb-3 label-left">
                <label htmlFor="email" className="form-label">Email <span className='red'>*</span></label>
                <div>
                {formData.email}
                
                </div>
                  
              </div>
              <div className='with-spacing'>
                <h3 className='product-upc'>PAYMENT METHOD</h3>
                <p className='product-upc-desc'>{paymentMethod === 'PayPal' ? 'PayPal' : 'Venmo'}</p>
                {paymentMethod === 'PayPal' && (
                  <div className="mb-3 label-left">
                    <label htmlFor="email" className="form-label">
                      <strong>PayPal Email </strong><span className='red'>*</span>
                    </label>
                    <div>{formData.paypalemail}</div>
                  </div>
                )}
                {paymentMethod === 'Venmo' && (
                  <div className="mb-3 label-left">
                    <label htmlFor="phone" className="form-label">
                      <strong>Venmo Phone </strong><span className='red'>*</span>
                    </label>
                    <div>{formData.venmoNumber}</div>
                  </div>
                )}
              </div>
              {formData.selectedButton === "PayPal" && (
                <div className="mb-3 label-left" style={{ marginTop: '10px' }}>
                  <label htmlFor="paypal-email" className="form-label" style={{ padding: '5px' }}>
                    <strong>PayPal Email<span className='red'>*</span></strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="paypalemail"
                    placeholder="Enter your PayPal account email address"
                    value={formData.paypalemail}
                    required
                    disabled // Add disabled attribute to make it not editable
                  />
                </div>
              )}
              <div className='with-spacing'>
                
              </div>
              <div>
                <h3 className='product-upc'>RECEIPT UPLOAD</h3>
                
      {renderFilePreviews()}
        {/* <img className='receipt-example-image' src='images/receipt-example.png' alt='Receipt Example'/> */}
              </div>

              <div className="row col with-spacing">
                <div className="form-check mb-3">
                <input
                type="checkbox"
                className="form-check-input"
                id="agreeToTerms"
                defaultChecked={true} // Set the checkbox to be checked by default
                disabled // Add disabled attribute to make it not editable
              />
              <label className="form-check-label" htmlFor="agreeToTerms">
                I agree to the <a href="#">Terms and Conditions</a> and to receive emails that are relevant to my rebate.<span className='red'>*</span>
              </label>
                </div>
              </div>
              <div className='with-spacing'></div>
              <div className='button-container'>
              </div>
            </form>
          </div><div className='button-container'>
                  <button
                    type="submit"
                    className="third-width" 
                    onClick={handleSubmitClick}
                    id='review-button'
                  >
                    SUBMIT
                  </button>
                  <button type="button" className="third-width" id='edit-button' onClick={edit}>EDIT</button>
                  <button type="button" className="third-width" id='cancel-button' onClick={handleCancelClick}>CANCEL</button>
                
                </div>
                {/* feel free to replace this spinner! */}
                {isLoading && <div className="lds-hourglass"></div>}
        </div>
      </div>
    </div>
  );
}

export default PreviewSubmission;
