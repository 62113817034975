import { content } from './content';

export default {
  dpn: {
    id: 'publix',
    name: 'Publix',
  },
  storageKey: 'pbyt',
  // mpid: {
  //   production: process.env.REACT_APP_MPID,
  //   local: '',
  // },
  token: '', //captcha token
  images: {
    logo: 'publix-logo.png',
  },
  links: {
    register: 'https://www.publix.com/myaccount/register',
  },
  content: content,
};
