export function trackClipError (mdid, location, error_message, username){
    console.log('trackClipError redux');
    console.log(location)
    console.log(error_message)
    window.gtag('event', 'offer_clip_error', { 
      offer_id: mdid,
      module_name: location,
      error_code: error_message,
      version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A',
      retailer_member_id: username
    });
  }

export function trackInfluencerClick(url, post_order, post_title, platform){
  console.log('InfluencerClick')
  console.log(url)
  console.log(post_order)
  console.log(post_title)
  console.log(platform)
  window.gtag('event', 'click', { 
    url: url,
    post_order: post_order,
    post_title: post_title,
    platform: platform,
    version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A'
  });
}

export function trackLoginError (location, error_message){
  console.log('trackLoginError');
  console.log(location)
  console.log(error_message)
  window.gtag('event', 'login_error', { 
    error_code: error_message,
    version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A'
  });
}

export function trackClick (name, location){
  console.log('trackClick');
  console.log(name)
  console.log(location)
  window.gtag('event', 'clip', { 
    name: name,
    location: location,
    version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A'
  });
}