import React, { useEffect, useState } from 'react';
import CartContentItem from './item';

import './content.scss';

//display add-to-cart section
function CartContent(props) {
  // console.log(siteConfig.components)
  const block = props.page.components.find(component => component.block_type === 'AddToCartBlock');
  const data = block.addtocart_set;
  let a2cHeader = block.a2c_header;
  let a2cSubtitle = block.a2c_subtitle_text;
  if (data.a2cHeader)
  {
    a2cHeader = data.a2cHeader;
  }

  return (
    <>
      {(data.length > 0) && (
        <div className="content-page">
          <div className="w-full max-w-[1440px] mx-auto px-4 py-8 flex flex-col justify-start items-center gap-4">
            {a2cHeader && <p className={`text-center text-zinc-800 text-4xl font-extrabold font-['Switzer Variable'] leading-tight sm:text-5xl md:text-6xl lg:text-7xl ${a2cSubtitle ? 'noBottomPadding' : ''}`}>{a2cHeader}</p>}
            {a2cSubtitle && <p className="w-full max-w-[680px] text-center text-zinc-800 text-base font-normal font-['Switzer Variable'] leading-snug sm:text-lg md:text-xl">{a2cSubtitle}</p>}
          </div>
          <div className="cart-content">
            {data.map((item, i) => {
              return <CartContentItem page={props.page} item={item} key={i} />;
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default CartContent;
