import React from 'react';

function ChatbotButton(page) {
  // Find the ChatbotButtonBlock component in the page's components
  const block = page.page.components.find(
    (component) => component.block_type === 'ChatbotButtonBlock'
  );

  // Log the block for debugging purposes
  console.log(block);
  console.log('chatbot buttons^');

  return (
    <div className='chatbot-button-block-container col' style={{
      width: `${block.width}%`,
      backgroundColor: block.background_color,
      textAlign: 'center', // Center align everything
      display: 'flex',
      flexDirection: 'column', // Stack buttons vertically
      alignItems: 'center', // Center horizontally
      justifyContent: 'center', // Center vertically
    }}>
      {block.button_1_text && (
        <a href={`${block.button_1_link}`}>
          <div 
            style={{
              backgroundColor: block.button_1_color,
              color: block.button_1_text_color,
              borderRadius: `${block.border_radius}px`,
              margin: block.margin,
              padding: `9px 15px`,
              width: 'auto',
              fontSize: '0.9rem',
              boxShadow: '1px 1px 5px 0 rgba(0, 0, 0, 0.2)'
            }} 
          >
            {block.button_1_text}
          </div>
        </a>
      )}
      {block.button_2_text && (
        <a href={`${block.button_2_link}`}>
          <div 
            style={{
              marginTop: block.button_1_text ? 0 : block.margin,
              marginBottom: block.margin,
              color: block.button_2_text_color,
              width: 'auto',
              fontSize: '0.9rem',
              padding: `${block.padding}px`,
              backgroundColor: block.button_2_color,
              borderRadius: `${block.border_radius}px`,
              padding: `9px 15px`,
              width: 'auto',
              fontSize: '0.9rem',
              boxShadow: '1px 1px 5px 0 rgba(0, 0, 0, 0.2)'
            }}  
          >
            {block.button_2_text}
          </div>
        </a>
      )}
    </div>
  );
}

export default ChatbotButton;
