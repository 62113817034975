import React, { useState } from 'react';
import './Rebates.scss';
import { Row, Col } from 'react-bootstrap';
import SubmissionForm from './submissionForm';

function Rebates(page) {
  let pageData=page.page
return (<>
    <SubmissionForm page={pageData}/>
</>
)
}

export default Rebates;
