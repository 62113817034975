// useConvertImageToText.js
import axios from 'axios';
import Tesseract from 'tesseract.js';

const useConvertImageToText = (page) => {
  console.log("useConvertImageToText");
  console.log(page);
  const loyaltyBlock = page.siteConfig.components.find(component => component.block_type === 'LoyaltyBlock');
  const programId = loyaltyBlock.loyalty_program_id;
  
  // Temporarily set to Publix, this needs to be added to backend
  const retailer = 'Publix';

  const convertImageToText = async (phoneNumber) => {
    try {
      const imageURL = `https://ice-content.dpn.inmar.com/v2/images/publix/points-total.png?usernameType=phone&username=${phoneNumber}&program=${programId}&textColor=black`;
      const response = await axios.get(imageURL, {
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      const { data: { text } } = await Tesseract.recognize(blob, 'eng', {
        logger: (m) => console.log(m),
      });
      console.log(text);
      return text;
    } catch (error) {
      console.error('Error converting image to text', error);
      throw error;
    }
  };

  return convertImageToText;
};

export default useConvertImageToText;