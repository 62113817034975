import React, { useContext } from 'react';
import './logoGarden.scss';
import { Link } from 'react-router-dom';
import { SiteConfigContext } from '../SiteConfigContext';

function GardenImages(props) {
  const siteConfig = useContext(SiteConfigContext)
  const page = props.page;

  const gardenBlock = page.components.find(component => component.block_type === 'LogoGardenBlock');

  const gardenStyle = {
    backgroundColor: gardenBlock.color_background,
  };

  const imgStyle = {
    height: gardenBlock.height,
  };

  // this changes the link address for preview mode or production
  function renderLinkAddress(item){
    if (process.env.REACT_APP_PREVIEW_MODE === 'true') {
      if (item.link) {
        // for preview mode it sets it to localhost/cloudront then the brandsite name then the item link
        return `${window.location.origin}/${siteConfig[0].brandsite_name}/${item.link}`;
      } 
      // if item.link is null it just has localhost/cloudfront then the brandsite name
      else {
        return `${window.location.origin}/${siteConfig[0].brandsite_name}`;
      }
    } else {
      // if preview mode is turned off it does not include the brandsite name in the url
      if (item.link) {
        return `${window.location.origin}/${item.link}`;
      } else {
        return `${window.location.origin}`;
      }
    }
  }

  return (
    <div className='garden' style={gardenStyle}>
      {gardenBlock.gardenimage_set.map((item, i) => (
        <Link to={renderLinkAddress(item)}
        // target={item.link ? '_blank' : ''}
        rel="noopener noreferrer"
        key={i}
      >      
          <img
            className={`gardenImg`}
            src={item.image}
            alt={item.alt_text || ''}
            style={imgStyle}
          />
        </Link>
      ))}
    </div>
  );
}

export default GardenImages;
