import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config';
import { trackClipError } from '../../utils/gaTrackingFunctions';
import user from './user';

const initialState = {
  errorMsg: false,
  clipped: false,
  show_login: false,
  mdid: '',
  location: '',
  collections: {
    available: [],
    clipped: [],
    featured: []
  },
};

export const STORE_CLIPPED = (mdid) => {
  let storage = localStorage.getItem('pbyt');
  if (storage) {
    const storedObj = JSON.parse(storage);
    let myIds = storedObj.myIds;
    storedObj.myIds = myIds.concat(',', mdid);
    localStorage.setItem('pbyt', JSON.stringify(storedObj));
  }
};

function trackClipEvent (mdid, location, username){
  
  window.gtag('event', 'offer_clip', { 
    offer_id: mdid,
    version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A',
    module_name: location,
    retailer_member_id: username
  });
}

export const CLIP_OFFER = createAsyncThunk(
  'offers/load',
  async (options, thunkAPI) => {
    const response = await fetch(config.baseUrl + options.endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': 'publix',
        Origin: window.location.origin,
      },
      method: options.method,
      body: JSON.stringify(options.body),
    })
      .then((data) => {
        let errorMsg;
        switch (data.status) {
          case 409:
            errorMsg = "Oops! Looks like you've clipped this offer.";
            break;
          case 404:
            errorMsg = "Oops! Looks like you're not signed up.";
            break;
          case 410:
            errorMsg = "Oops! Looks like you're not signed up.";
            break;
          case 400:
            errorMsg = 'Oops! Something went wrong, please try again.';
            break;
          default:
            break;
        }
        let returnObj = {
          code: data.status,
          errorMsg: errorMsg,
          mdid: options.body.id,
        };
        if (data.status !== 409 && data.status !== 200) {
          //invalid user
          const errorMessage = `${data.status} - ${errorMsg}`;
          trackClipError(options.body.id, options.body.location, errorMessage)
          return Object.assign(returnObj, { success: false });
        } else {
          // clip if this is not loyalty
          if (options.is_qualifying_offer === false || typeof options.is_qualifying_offer === 'undefined') {
            trackClipEvent(options.body.id, options.body.location, options.body.username);
          } 
          STORE_CLIPPED(options.body.id);
          return Object.assign(returnObj, { success: true });
        }
      })
      .catch((data) => {
        console.log('catch', data);
        return data;
      });

    return response;
  },
);
const slice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    CLIP_ERROR: (state, action) => {
      return Object.assign({}, state, { errorMsg: action.payload.errorMsg });
    },
    SHOW_LOGIN: (state, action) => {
      const { mdid, show, location = 'unknown', isBulkClip = false } = action.payload;
      return Object.assign(state, {
        show_login: show,
        mdid: mdid,
        location: location,
        isBulkClip: isBulkClip,
      });
    },
    SET_OFFERS: (state, action) => {
      const { collections } = action.payload;
      return Object.assign({}, state, {
        collections,
      });
    },
  },
  extraReducers: {
    [CLIP_OFFER.fulfilled]: (state, action) => {
      state.status = action.payload.status;
      let offers = state.collections.available.concat(
        state.collections.featured,
      );
      let match = offers.find(
        (cpn) => cpn.mdid === action.payload.mdid,
      );
      if (action.payload.code === 409 || action.payload.code === 200) {
        if (action.meta.arg.is_qualifying_offer) {
        } else {
          match.clipped = true;
        }
      }
      //state.errorMsg = action.payload.errorMsg;
    },
  },
});

export const { CLIP_ERROR, SHOW_LOGIN, SET_OFFERS } = slice.actions;
// Reducer
export default slice.reducer;
